import React from "react";
import { Routes, Route } from "react-router-dom";
import { SRow, SCol } from "@avalara/skylab-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getProduct, selectSession } from "../../app/sessionSlice";
import { certificateExemptionManagementTag, subscription, tier } from "./Subscription";
import { isNoAccessUser, vendorExemptions } from "../../shared/sessionUtility";
import { matchSubscriptions } from "../../shared/Utils";
import Customers from "../search/customers/Customers";
import Overview from "../overview/Overview";
import CertificateRequestFormsFilter from "../settings/tabs/CertificateRequestForms/CertificateRequestFormsFilter";
import VendorRequestForms from "../settings/tabs/CertificateRequestForms/VendorRequestForms";
import ErrorPage from "../errorHandling/ErrorPage";
import NoAccessErrorPage from "../errorHandling/NoAccessErrorPage";
import CertificateRequestFormsGrid from "../settings/tabs/CertificateRequestForms/CertificateRequestFormsGrid";

const conditionalComponents = {
    homeComponent: {
        [subscription.ECMPremium]: <Overview />,
        [subscription.ECMEssentials]: <Customers />,
        [subscription.VEPREMIUM]: <Overview />,
    },
    certificateRequestFormsFilter: {
        [subscription.ECMPremium]: (
            <SRow>
                <SCol>
                    <CertificateRequestFormsFilter />
                    <CertificateRequestFormsGrid />
                </SCol>
            </SRow>
        ),
        [subscription.VEPREMIUM]: (
            <SRow>
                <SCol>
                    <VendorRequestForms />
                </SCol>
            </SRow>
        ),
    },
};

function Protected(props) {
    const session = useSelector(selectSession, shallowEqual);
    const dispatch = useDispatch();
    let currentSubscription = useSelector(getProduct, shallowEqual);
    const isvendorExemptions = dispatch(vendorExemptions());
    const isNoAccessUserFlag = dispatch(isNoAccessUser());

    const proOrPremium = () => {
        return (
            session.subscriptions.includes(subscription.ECMStandard) ||
            session.subscriptions.includes(subscription.ECMPro) ||
            session.subscriptions.includes(subscription.ECMPremium) ||
            session.tier === tier.ENT
        );
    };

    const noAccessDOM = (
        <Routes>
            <Route
                path="*"
                element={<NoAccessErrorPage type={404} setHideHeader={props.setHideHeader} />}
            />
        </Routes>
    );
    let dom = (
        <Routes>
            <Route
                path="*"
                element={<ErrorPage type={404} setHideHeader={props.setHideHeader} />}
            />
        </Routes>
    );
    // for no access user
    if (isNoAccessUserFlag) {
        return noAccessDOM;
    }

    // in case the account has no subscriptions, give them the lowest tier
    if (!matchSubscriptions(Object.values(subscription), currentSubscription)) {
        currentSubscription = [subscription.AvaTaxExemptions];
    }

    const allowedSubscriptions =
        props?.allowedSubscriptions &&
        matchSubscriptions(props?.allowedSubscriptions, currentSubscription);

    const cemRole = // if logged in user is CEM user and feature needs CEM check
        !session.ecmRole?.filter(x => x.startsWith(certificateExemptionManagementTag)).length ||
        !props.allowedCEMRoles ||
        matchSubscriptions(props.allowedCEMRoles, session.ecmRole);

    const retRole = // if logged in user is RET user then allowed features must have RET allowedRETRoles
        !session.isRetailer ||
        (props.allowedRETRoles && matchSubscriptions(props.allowedRETRoles, session.ecmRole));

    // const internalUser = // if logged in user is internal user and feature needs internal user check
    //     props.internalUser === undefined || (props.internalUser && session.internalUser);

    if (props?.conditional) {
        let render = subscription.ECMEssentials;
        if (proOrPremium()) {
            render = subscription.ECMPremium;
        } else if (isvendorExemptions) {
            render = subscription.VEPREMIUM;
        }
        if (conditionalComponents?.[props.conditional]?.[render]) {
            dom = conditionalComponents[props.conditional][render];
        }
    } else if (props?.children) {
        if (allowedSubscriptions && cemRole && retRole) {
            dom = props.children;
        } else {
            dom = noAccessDOM;
        }
    }

    return dom;
}
export default Protected;
