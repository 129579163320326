import { SCol, SRow } from "@avalara/skylab-react";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import featureFlag from "../../featureToggler/featureFlag";
import ImportCustomers from "../bulkImport/ImportCustomers";
import ImportVendors from "../bulkImport/ImportVendors";
import Campaign from "../campaigns/Campaign";
import CertificateForms from "../content/CertificateForms/CertificateForms";
import ExemptEntities from "../content/ExemptEntities/ExemptEntities";
import TaxAnswers from "../content/TaxAnswers/TaxAnswers";
import TaxLaws from "../content/TaxLaws/TaxLaws";
import CoverLetter from "../coverLetters/CoverLetter";
import CoverLetterGrid from "../coverLetters/CoverLetterGrid";
import CoverLetterHeader from "../coverLetters/CoverLetterHeader";
import VendorLetterGrid from "../coverLetters/VendorLetterGrid";
import Customer from "../customers/Customer";
import CustomerHeader from "../customers/CustomerHeader";
import HealthCheck from "../healthCheck/HealthCheck";
import Overview from "../overview/Overview";
import Resources from "../overview/resources/Resources";
import Promo from "../provision/Promo";
import RequestDashboard from "../requests/RequestDashboard";
import Requests from "../requests/Requests";
import BulkRequest from "../requests/bulkRequest/BulkRequest";
import SingleRequest from "../requests/singleRequest/SingleRequest";
import VendorRequest from "../requests/vendorRequests/VendorRequest";
import ValidationHistory from "../review/ValidationHistory";
import Certificate from "../review/certificate/Certificate";
import CertificateDetail from "../review/certificate/CertificateDetail";
import Validation from "../review/validation/Validation";
import ValidationDetails from "../review/validation/ValidationDetails";
import RoleManagementPage from "../roleManagementPage/RoleManagementPage";
import BulkUploadCertificates from "../search/BulkUpload/BulkUploadCertificates";
import SavedSearches from "../search/SavedSearches/SavedSearches";
import VendorCertificates from "../search/VendorCertificates/VendorCertificate";
import VendorCertificateHeader from "../search/VendorCertificates/VendorCertificateHeader";
import SendPackage from "../search/VendorPackages/SendPackage";
import VendorPackageContent from "../search/VendorPackages/VendorPackageContent";
import VendorPackageCreate from "../search/VendorPackages/VendorPackageCreate";
import VendorPackageEdit from "../search/VendorPackages/VendorPackageEdit";
import Certificates from "../search/certificates/Certificates";
import Customers from "../search/customers/Customers";
import PendingSearch from "../search/pending/PendingSearch";
import Vendors from "../search/vendors/Vendors";
import ManageExemptionReasons from "../settings/ManageExemptionReasons/ManageExemptionReasons";
import Settings from "../settings/Setting";
import CustomFields from "../settings/customFields/CustomFields";
import CertificatesCustomFields from "../settings/customFields/certificatesCustomFields/CertificatesCustomFields";
import CustomersCustomFields from "../settings/customFields/customersCustomFields/CustomersCustomFields";
import ManageInvalidReasons from "../settings/invalidReason/ManageInvalidReasons";
import Labels from "../settings/labels/Labels";
import CertificateLabels from "../settings/labels/certificatesLabels/CertificateLabels";
import CustomerLabels from "../settings/labels/customersLabels/CustomerLabels";
import Region from "../settings/region/Region";
import Retail from "../settings/retail/Retail";
import RetailLocations from "../settings/retail/retailLocations/RetailLocations";
import RetailSettings from "../settings/retail/retailSettings/RetailSettings";
import AdvancedSettings from "../settings/tabs/AdvancedSettings";
import CertExpressSettings from "../settings/tabs/CertExpressSettings";
import CertificateOutgoingRequestFormsFilter from "../settings/tabs/CertificateRequestForms/CertificateOutgoingRequestFormsFilter";
import CertificateOutgoingRequestFormsGrid from "../settings/tabs/CertificateRequestForms/CertificateOutgoingRequestFormsGrid";
import CertificateRequestForms from "../settings/tabs/CertificateRequestForms/CertificateRequestForms";
import CertificateRequestFormsContent from "../settings/tabs/CertificateRequestForms/CertificateRequestFormsContent";
import CertificateRequestFormsFilter from "../settings/tabs/CertificateRequestForms/CertificateRequestFormsFilter";
import CertificateRequestFormsGrid from "../settings/tabs/CertificateRequestForms/CertificateRequestFormsGrid";
import CertificateRequests from "../settings/tabs/CertificateRequests";
import General from "../settings/tabs/General";
import InternalUserSettings from "../settings/tabs/InternalUserSettings";
import ManageProductCategories from "../settings/productCategories/ManageProductCategories";
import Protected from "./ProtectedRoutes";
import {
    advanceSubscription,
    ecmRole,
    ecmSubscription,
    lowerSubscription,
    subscription,
    userRoleAdminSubscription,
    vendorAvaTaxAPSubscription,
    vendorPremiumOnlySubscription,
    vendorSubscription,
} from "./Subscription";
import { retailRoutes } from "./RetailRouteConfig";

const routes = (setHideHeader, setHideNav) => [
    {
        id: uuidv4(),
        path: "/",
        element: (
            <Protected
                conditional="homeComponent"
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}
            />
        ),
    },
    {
        id: uuidv4(),
        path: "/overview",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Overview />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/settings",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={[
                    ecmRole.CEMAdmin,
                    ecmRole.CEMManager,
                    ecmRole.CEMCertificateReviewer,
                    ecmRole.CEMViewer,
                    ecmRole.CEMPreValidator,
                ]}
                internalUser
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Settings />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <General />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "general",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <General />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "certificateRequests",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateRequests />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "default-exemption-forms",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        name="form"
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateRequestForms />
                    </Protected>
                ),
                children: [
                    {
                        index: true,
                        id: uuidv4(),
                        element: (
                            <Protected
                                conditional="certificateRequestFormsFilter"
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}
                            />
                        ),
                    },
                    {
                        id: uuidv4(),
                        path: "incoming",
                        element: (
                            <Protected
                                allowedSubscriptions={advanceSubscription}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <SRow>
                                    <SCol>
                                        <CertificateRequestFormsFilter />
                                        <CertificateRequestFormsGrid />
                                    </SCol>
                                </SRow>
                            </Protected>
                        ),
                    },
                    {
                        id: uuidv4(),
                        path: "outgoing",
                        element: (
                            <Protected
                                allowedSubscriptions={vendorSubscription}
                                setHideHeader={setHideHeader}
                                setHideNav={setHideNav}>
                                <SRow>
                                    <SCol>
                                        <CertificateOutgoingRequestFormsFilter />
                                        <CertificateOutgoingRequestFormsGrid />
                                    </SCol>
                                </SRow>
                            </Protected>
                        ),
                    },
                ],
            },

            {
                id: uuidv4(),
                path: "certexpress-settings",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertExpressSettings />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "advanced-settings",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <AdvancedSettings />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "internal-user-settings",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <InternalUserSettings />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/promo",
        element: <Promo />,
    },
    {
        id: uuidv4(),
        path: "settings/manage-regions",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewExemptionRegion.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Region />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/custom-fields",
        element: (
            <Protected
                allowedSubscriptions={[subscription.ECMPremium, subscription.VEPREMIUM]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomFields />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "certificates-custom-fields",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, subscription.VEPREMIUM]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificatesCustomFields />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "customers-custom-fields",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, subscription.VEPREMIUM]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CustomersCustomFields />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "settings/retail",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                allowedCEMRoles={featureFlag.settings.viewRetailLocations.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Retail />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "locations",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailLocations />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "retail-settings",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.retailLocations.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <RetailSettings />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "settings/labels",
        element: (
            <Protected
                allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                allowedCEMRoles={featureFlag.settings.viewLabels.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Labels />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                path: "certificate-labels",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CertificateLabels />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "customer-labels",
                element: (
                    <Protected
                        allowedSubscriptions={featureFlag.settings.viewLabels.subscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CustomerLabels />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/search/customers",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...advanceSubscription,
                    ...vendorAvaTaxAPSubscription,
                ]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendors",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Vendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/customer/:id",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomerHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor/:id",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CustomerHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/certificates",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...ecmSubscription,
                    ...vendorAvaTaxAPSubscription,
                ]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/pending",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <PendingSearch />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/add-customer",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customer setVendor={false} />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/add-vendor",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customer setVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/review",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedRETRoles={[ecmRole.RETManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Validation />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: "true",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationDetails />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "history/:certificateID",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationHistory />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: ":certificateID",
                element: (
                    <Protected
                        allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                        allowedRETRoles={[ecmRole.RETManager]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <ValidationDetails />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/certificate",
        element: (
            <Protected
                allowedSubscriptions={[
                    ...lowerSubscription,
                    ...ecmSubscription,
                    ...vendorSubscription,
                ]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificate />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: "true",
                element: <CertificateDetail />,
            },
            {
                id: uuidv4(),
                path: "history/:certificateID",
                element: <ValidationHistory />,
            },
            {
                id: uuidv4(),
                path: ":certificateID",
                element: <CertificateDetail />,
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/import-customers",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ImportCustomers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/import-vendors",
        element: (
            <Protected
                allowedSubscriptions={[...vendorSubscription, ...vendorAvaTaxAPSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ImportVendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/requests/create",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Requests />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/requests",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <RequestDashboard />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <BulkRequest />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "singleRequests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <SingleRequest />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "bulkRequests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <BulkRequest />
                    </Protected>
                ),
            },
        ],
    },
    {
        id: uuidv4(),
        path: "/campaigns/:campaignID",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Campaign />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/tax-answers",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <TaxAnswers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/tax-laws",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <TaxLaws />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/exempt-entities",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ExemptEntities />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources/certificate-forms",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CertificateForms />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/resources",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Resources />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/customers",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Customers />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/certificates",
        element: (
            <Protected
                allowedSubscriptions={[...lowerSubscription, ...ecmSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Certificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/vendor-certificates",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved/search/vendors",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <Vendors />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/saved-searches",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <SavedSearches />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/certificate-forms",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CertificateRequestFormsContent />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor-requests/:id/",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorRequest />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-certificates",
        element: (
            <Protected
                allowedSubscriptions={vendorSubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificateHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorCertificateHeader />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/create",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageCreate />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/add-certificates/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageEdit />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/vendor-packages/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <VendorPackageContent />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/send-package/:id",
        element: (
            <Protected
                allowedSubscriptions={vendorPremiumOnlySubscription}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <SendPackage />
            </Protected>
        ),
    },

    {
        id: uuidv4(),
        path: "/add-cover-letter",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManage, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter />
            </Protected>
        ),
    },

    {
        id: uuidv4(),
        path: "/add-vendor-letter",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorPremiumOnlySubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/cover-letter/:id",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor={false} />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/vendor-letter/:id",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetter isVendor />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/search/bulk-upload",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <BulkUploadCertificates />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "/user-role-management/:userId",
        element: (
            <Protected
                allowedSubscriptions={userRoleAdminSubscription}
                allowedCEMRoles={[ecmRole.CEMAdmin]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <RoleManagementPage />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/manage-exempt-reasons",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewExemptionReason.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageExemptionReasons />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "health-check",
        element: <HealthCheck />,
    },
    {
        id: uuidv4(),
        path: "settings/manage-invalid-reasons",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={featureFlag.settings.viewInvalidReason.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageInvalidReasons />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "settings/manage-product-categories",
        element: (
            <Protected
                allowedSubscriptions={advanceSubscription}
                allowedCEMRoles={featureFlag.settings.viewProductCategories.ecmRole}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <ManageProductCategories />
            </Protected>
        ),
    },
    {
        id: uuidv4(),
        path: "cover-letters",
        element: (
            <Protected
                allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                allowedCEMRoles={[ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator]}
                setHideHeader={setHideHeader}
                setHideNav={setHideNav}>
                <CoverLetterHeader />
            </Protected>
        ),
        children: [
            {
                id: uuidv4(),
                index: true,
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CoverLetterGrid />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "requests",
                element: (
                    <Protected
                        allowedSubscriptions={advanceSubscription}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <CoverLetterGrid />
                    </Protected>
                ),
            },
            {
                id: uuidv4(),
                path: "vendors",
                element: (
                    <Protected
                        allowedSubscriptions={[...advanceSubscription, ...vendorSubscription]}
                        setHideHeader={setHideHeader}
                        setHideNav={setHideNav}>
                        <VendorLetterGrid />
                    </Protected>
                ),
            },
        ],
    },
    retailRoutes(setHideHeader, setHideNav),
    {
        id: uuidv4(),
        path: "*",
        element: <Protected setHideHeader={setHideHeader} setHideNav={setHideNav} />,
    },
];

export default routes;
