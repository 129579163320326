import {
    advanceSubscription,
    ecmSubscription,
    tier,
    subscription,
    ecmRole,
    avataxRole,
    vendorSubscription,
    lowerSubscription,
    vendorPremiumOnlySubscription,
    vendorAvaTaxAPSubscription,
    ecmRETRole,
} from "../features/navigation/Subscription";

const featureFlag = {
    validatedCertificate: {
        delete: {
            name: "delete",
            description: "Delete a certificate",
            subscription: ["All"],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
        validate: {
            name: "validate",
            description: "Validate a certificate",
            subscription: advanceSubscription,
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
            ],
        },
        vendorSave: {
            name: "vendorSave",
            description: "Validate a certificate",
            subscription: vendorSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
        saveAsValid: {
            name: "saveAsValid",
            description: "Validate a certificate(only save as valid, for lower subscriptions)",
            subscription: lowerSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
        upload: {
            name: "upload",
            description: "File upload",
            subscription: [...ecmSubscription, subscription.VEPRO, subscription.VEPREMIUM],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        attachments: {
            name: "attachments",
            description: "Supplemental files of certificate",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        comments: {
            name: "comments",
            description: "Manage comments on certificate",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        notification: {
            name: "Notification",
            description: "Send Notification",
            subscription: advanceSubscription,
            tier: [],
        },
        pauseResume: {
            name: "pauseResume",
            description: "pause and resume button",
            subscription: ["All"],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            tier: [],
            checkAdmin: true,
        },
        customFields: {
            name: "customFields",
            description: "certificate custom fields",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.MSUser],
        },
        showInvalidAsOption: {
            name: "showInvalidAsOption",
            description: "certificate custom fields",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        certificateLabels: {
            name: "certificateLabels",
            description: "certificate labels",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
        },
    },
    reviewCertificate: {
        add: {
            name: "add",
            description: "Add a certificate",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        validate: {
            name: "validate",
            description: "Validate a certificate",
            subscription: ["All"],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
            ],
            checkAdmin: true,
        },
        delete: {
            name: "delete",
            description: "Delete a certificate",
            subscription: ["All"],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        upload: {
            name: "upload",
            description: "File upload",
            subscription: [...ecmSubscription, subscription.VEPRO, subscription.VEPREMIUM],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        attachments: {
            name: "attachments",
            description: "Supplemental files of certificate",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        comments: {
            name: "comments",
            description: "Comments on certificate",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        split: {
            name: "split",
            description: "File split",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        assignRelease: {
            name: "assignRelease",
            description: "assign and release button",
            subscription: [subscription.ECMPremium],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.MSUser,
            ],
            tier: [],
            checkAdmin: true,
        },
        escalate: {
            name: "escalate",
            description: "escalate certificates",
            subscription: [subscription.ECMPremium],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.RETManager,
                ecmRole.MSUser,
            ],
            tier: [],
            checkAdmin: true,
        },
    },
    validatedSearchPage: {
        viewValidatedCertificates: {
            name: "viewValidateCertificates",
            description: "view validated certificates search page",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        bulkImport: {
            name: "bulkImport",
            description: "Bulk import of customers",
            subscription: ["All"],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
        manageEcmReports: {
            name: "manageEcmReports",
            description: "CRUD operation on save searches",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMManager,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        exportCertificateImages: {
            name: "exportCertificateImages",
            description: "Download Certificate Images in Bulk",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        export: {
            name: "export",
            description: "Export search filter result",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        bulkDelete: {
            name: "bulkDelete",
            description: "Delete certificates in bulk",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        bulkEdit: {
            name: "bulkEdit",
            description: "Bulk update the certificates",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        manageCertificateColumns: {
            name: "manageCertificateColumns",
            description: "manage selected certificates columns to view in grid",
            subscription: [subscription.ECMPremium],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },

        ellipsisMenu: {
            name: "ellipsisMenu",
            description: "view ellipsis menu options",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
    },
    reviewSearchPage: {
        viewReviewSearchPage: {
            name: "viewReviewSearchPage",
            description: "view review search page",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        bulkDelete: {
            name: "bulkDelete",
            description: "Delete certificates in bulk",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMCertificateReviewer],
            checkAdmin: true,
        },
        zipUploadCertificate: {
            name: "zipUploadCertificate",
            description: "Zip upload certificates",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
    },
    customers: {
        manageCustomer: {
            name: "manageCustomer",
            description: "Create, edit, delete a customer",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        export: {
            name: "manageCustomerExport",
            description: "Export search filter result",
            subscription: [...lowerSubscription, ...advanceSubscription],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        customFields: {
            name: "customFields",
            description: "customer custom fields",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
        },
        customerLabels: {
            name: "customerLabels",
            description: "customer labels",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
        },
        manageCustomersColumns: {
            name: "manageCustomersColumns",
            description: "manage selected customers columns to view in grid",
            subscription: [subscription.ECMPremium],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.RETManager,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        taxRegions: {
            name: "taxRegions",
            description: "Tax Regions of the customer",
            subscription: [...ecmSubscription, subscription.VEPREMIUM, subscription.VEPRO],
            tier: [tier.ENT],
        },
        parentTag: {
            name: "parent Tag",
            description: "Parent customer tag visibility",
            subscription: advanceSubscription,
            tier: [],
        },
        relatedCustomer: {
            name: "parent Tag",
            description: "Parent customer tag visibility",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
        },
        duplicateCustomer: {
            name: "duplicatecustomers",
            description: "Duplicate customer tag visibility",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
        },
        saveSearches: {
            name: "saveSearches",
            description: "Save filtered result",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            checkAdmin: true,
        },
        bulkDelete: {
            name: "bulkDelete",
            description: "Delete customers in bulk",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
    },
    settings: {
        requireCertificateImage: {
            name: "requireCertificateImage",
            description: "This Setting forces user to upload image while creating certificate",
            subscription: advanceSubscription,
            tier: [tier.ENT],
        },
        general: {
            name: "general",
            description: "Description of your products or services",
            subscription: [...advanceSubscription, subscription.VEPREMIUM, subscription.VEPRO],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        automations: {
            name: "automations",
            description: "Request certificates automatically",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        requestingCertficateExemptionMatrix: {
            name: "requestingCertficateExemptionMatrix",
            description: "Certificate creation tool",
            subscription: [...advanceSubscription],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        sendingCertficateExemptionMatrix: {
            name: "sendingCertficateExemptionMatrix",
            description: "send certificate",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        requestingCertExemptionMatrixUpdate: {
            name: "requestingCertExemptionMatrixUpdate",
            description: "Update the exemption matrix of reqesting certificates",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        sendingCertExemptionMatrixUpdate: {
            name: "sendingCertExemptionMatrixUpdate",
            description: "Update the exemption matrix of sending certificates",
            subscription: [subscription.VEPREMIUM, subscription.VEPRO],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        vendorOptions: {
            name: "vendorOptions",
            description: "Update the vendor options",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        viewExemptionReason: {
            name: "viewExemptionReason",
            description: "View all exemption reasons",
            subscription: [
                subscription.ECMPremium,
                subscription.ECMPro,
                subscription.VEPREMIUM,
                subscription.VEPRO,
            ],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        addExemptionReason: {
            name: "addExemptionReason",
            description: "Add an exemption reason",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editExemptionReason: {
            name: "editExemptionReason",
            description: "Edit an exemption reason",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteExemptionReason: {
            name: "deleteExemptionReason",
            description: "Delete an exemption reason",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        disableExemptionReason: {
            name: "disableExemptionReason",
            description: "Disable an exemption reason",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        viewExemptionRegion: {
            name: "viewExemptionRegion",
            description: "View all exemption regions",
            subscription: [
                subscription.ECMPremium,
                subscription.ECMPro,
                subscription.VEPREMIUM,
                subscription.VEPRO,
            ],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        addExemptionRegion: {
            name: "addExemptionRegion",
            description: "Add an exemption region",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editExemptionRegion: {
            name: "editExemptionRegion",
            description: "Edit an exemption region",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteExemptionRegion: {
            name: "deleteExemptionRegion",
            description: "Delete an exemption region",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        viewInvalidReason: {
            name: "viewInvalidReason",
            description: "View all invalid reasons",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        addInvalidReason: {
            name: "addInvalidReason",
            description: "Add an invalid reason",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editInvalidReason: {
            name: "editInvalidReason",
            description: "Edit an invalid reason",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteInvalidReason: {
            name: "deleteInvalidReason",
            description: "Delete an invalid reason",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },

        viewCustomField: {
            name: "viewCustomField",
            description: "View all customers & certificates custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        viewCustomersCustomField: {
            name: "viewcustomersCustomField",
            description: "View all customers custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.MSUser,
            ],
            checkAdmin: true,
        },
        addCustomersCustomField: {
            name: "addCustomersCustomField",
            description: "Add a customers custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },
        editCustomersCustomField: {
            name: "editCustomersCustomField",
            description: "Edit a customers custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },
        deleteCustomersCustomField: {
            name: "deleteCustomersCustomField",
            description: "Delete a customers custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },

        ViewCertificatesCustomField: {
            name: "viewcertificatesCustomField",
            description: "View all certificates custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.MSUser,
            ],
            checkAdmin: true,
        },
        addCertificatesCustomField: {
            name: "addCertificatesCustomField",
            description: "Add a certificates custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },
        editCertificatesCustomField: {
            name: "editCertificatesCustomField",
            description: "Edit a certificates custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },
        deleteCertificatesCustomField: {
            name: "deleteCertificatesCustomField",
            description: "Delete a certificates custom field",
            subscription: [subscription.ECMPremium, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.MSUser],
            checkAdmin: true,
        },

        viewLabels: {
            name: "viewLabels",
            description: "View all labels",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        addLabel: {
            name: "addLabel",
            description: "Add a label",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editLabel: {
            name: "editLabel",
            description: "Edit a label",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteLabel: {
            name: "deleteLabel",
            description: "Delete a label",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        internalSettings: {
            name: "internalSettings",
            description: "Manage the internal settings",
            subscription: advanceSubscription,
            internalUser: true,
            tier: [],
        },
        retailLocations: {
            name: "retailLocations",
            description: "Manage settings and retail sites",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        viewRetailLocations: {
            name: "viewRetailLocations",
            description: "View all retail locations",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.RETManager],
            checkAdmin: true,
            setting: "enableRetail",
        },
        addRetailLocations: {
            name: "addRetailLocations",
            description: "Add a retail location",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editRetailLocations: {
            name: "editRetailLocations",
            description: "Edit a retail location",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteRetailLocations: {
            name: "deleteRetailLocations",
            description: "Delete a retail location",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        viewProductCategories: {
            name: "viewProductCategories",
            description: "View all Product Categories",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
                ecmRole.MSUser,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        addProductCategories: {
            name: "addProductCategories",
            description: "Add a product category",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        editProductCategories: {
            name: "editProductCategories",
            description: "Edit a product category",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        deleteProductCategories: {
            name: "deleteProductCategories",
            description: "Delete a product category",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
        useProductCategoriesToggle: {
            name: "useProductCategoriesToggle",
            description: "Use product categories toggle",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
    },
    certificateFilters: {
        certificateImageFilter: {
            name: "certificateImageExists",
            description: "Filters certificate based on cerificate image existence",
            subscription: ecmSubscription,
            tier: [],
        },
        certificateLabels: {
            name: "certificateLabels",
            description: "certificate labels",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
        },
        reviewedBy: {
            name: "reviewedBy",
            description: "ECM account that reviewed the certificate",
            subscription: [subscription.ECMPremium, subscription.ECMPro],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
        },
    },
    requestCertificates: {
        coverLetter: {
            name: "showCoverLetterOptions",
            description: "This allows selection of certificate templates while requesting",
            subscription: [...advanceSubscription, ...vendorSubscription],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
        },
        createCoverLetter: {
            name: "createCoverLetterOptions",
            description: "This allows creation of certificate templates",
            subscription: [...advanceSubscription, subscription.VEPREMIUM],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
        },
        singleRequest: {
            name: "singleRequest",
            description: "Request Certificate button",
            subscription: [...ecmSubscription, subscription.VEPREMIUM, subscription.VEPRO],
            tier: [tier.ENT],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
        campaign: {
            name: "campaign",
            description: "Bulk request of certificates",
            subscription: advanceSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
        },
    },
    fvsUploader: {
        avs: {
            name: "Avs",
            description: "Automatically review certificates ",
            subscription: advanceSubscription,
            tier: [],
        },
    },
    vendors: {
        manageVendors: {
            name: "manageVendors",
            description: "Create and edit vendors",
            subscription: [...vendorSubscription, ...vendorAvaTaxAPSubscription],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        bulkImport: {
            name: "bulkImport",
            description: "Bulk import of vendors",
            subscription: [...vendorSubscription, ...vendorAvaTaxAPSubscription],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager],
            checkAdmin: true,
        },
        saveSearches: {
            name: "saveSearches",
            description: "Save filtered result",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        createAndSendCertificates: {
            name: "CreateAndSendCertificates",
            description: "Create and send certificates",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMCertificateReviewer],
            checkAdmin: true,
        },
        signaturePad: {
            name: "signaturePad",
            description: "Create signature",
            protected: true,
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMCertificateReviewer],
            checkAdmin: true,
        },
        vendorAdditionalInfo: {
            name: "vendorAdditionalInfo",
            description: "vendor Additional Info",
            subscription: vendorAvaTaxAPSubscription,
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMCertificateReviewer],
            checkAdmin: true,
        },
    },
    vendorCertificates: {
        viewVendorCertificates: {
            name: "viewVendorCertificates",
            description: "View vendor certificates",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        saveSearches: {
            name: "saveSearches",
            description: "Save filtered result",
            subscription: vendorSubscription,
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
        },
        manageCertificatePackages: {
            name: "manageCertificatePackages",
            description: "Create and edit certificate packages",
            subscription: vendorPremiumOnlySubscription,
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMCertificateReviewer],
            checkAdmin: true,
        },
    },
    overview: {
        insights: {
            name: "insights",
            description: "List different tasks that need action",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        favorites: {
            name: "favorites",
            description: "List different tasks that need action",
            subscription: advanceSubscription,
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
    },
    reports: {
        viewReports: {
            name: "viewReports",
            description: "List different saved reports",
            subscription: [...advanceSubscription],
            tier: [tier.ENT],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        viewVendorReports: {
            name: "viewVendorReports",
            description: "List different saved reports",
            subscription: [...vendorSubscription],
            tier: [],
            ecmRole: [
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMCertificateReviewer,
                ecmRole.CEMViewer,
            ],
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
    },
    afcContent: {
        associateTaxTypeData: {
            name: "associateTaxTypeData",
            description: "associate tax type data",
            subscription: [subscription.ECMProComms, subscription.ECMPremiumComms],
        },
    },
    retail: {
        index: {
            name: "retail",
            description: "Retail landing section containing search form",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [...ecmRETRole, ecmRole.CEMAdmin, ecmRole.CEMPreValidator],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        search: {
            name: "search",
            description: "Search customer and its certificatess",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [...ecmRETRole, ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        viewCustomerAndCertificate: {
            name: "viewCustomerAndCertificate",
            description: "View customer and its certificatess",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [...ecmRETRole, ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        editCustomerAndCertificate: {
            name: "editCustomerAndCertificate",
            description: "Manage customer and its certificatess",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [
                ecmRole.RETManager,
                ecmRole.RETAssociate,
                ecmRole.CEMAdmin,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        editCustomer: {
            name: "editCustomer",
            description: "Manage customer information",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [
                ecmRole.RETManager,
                ecmRole.RETAssociate,
                ecmRole.CEMAdmin,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        viewCustomer: {
            name: "viewCustomer",
            description: "View customer information",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [
                ecmRole.RETManager,
                ecmRole.RETAssociate,
                ecmRole.RETViewer,
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
                ecmRole.CEMPreValidator,
            ],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        tile: {
            name: "tile",
            description: "View retail tile in overview screen",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin, ecmRole.CEMManager, ecmRole.CEMPreValidator],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
            setting: "enableRetail",
        },
        selectLocation: {
            name: "selectLocation",
            description: "View the location selector",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [
                ecmRole.RETManager,
                ecmRole.RETAssociate,
                ecmRole.CEMAdmin,
                ecmRole.CEMManager,
            ],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
        enableRetailSetting: {
            name: "enableRetailSetting",
            description: "Enable retail checkbox in settings",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
            avataxRole: [avataxRole.ECMAccountUser, avataxRole.ECMCompanyUser],
        },
    },
    userRoleManagement: {
        assignRetailLocation: {
            name: "assignRetailLocation",
            description: "Assign retail locations",
            subscription: [subscription.ECMPremium],
            tier: [],
            ecmRole: [ecmRole.CEMAdmin],
            checkAdmin: true,
        },
    },
};

export default featureFlag;
